import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { useEffect } from "react"
import { Link } from "gatsby"

export default function AboutMe({ data }) {
  const seo = {
    title: "Gabinet medycyny estetycznej w Szczecinie - dr Maciej Pastucha",
    description: "Lekarz medycyny estetycznej w Szczecinie - dr n. med. Maciej Pastucha. Specjalista z ponad 20 letnim doświadczeniem w chirurgii plastycznej (estetycznej).",
    url: "https://mpastucha.pl/o-mnie"
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.AboutPageInit && window.$) {
        clearInterval(interval);
        window.AboutPageInit();
      }
    })
  });

  return (
    <Layout headerClasses={'mainheader-subpage mainheader-white'} classes={'bg-creme'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img src="/images/phone.svg" alt="Ikona telefon" /></a>
      <div className="fullwidth fullheight-sub hero-bg-about cover" />
      <section className="sct container about">
        <div className="row">
          <div className="col-10sm-center">
            <div className="row">
              <div className="col-4sm txt-h-dsc">
                <h1>dr n. med. Maciej Pastucha</h1>
                <p>Chirurg plastyk</p>
              </div>
              <div className="col-8sm">
                <div className="btn-list pull-right smplus-only">
                  <div className="row">
                    <div className="col-6xs btn btn-border">30 lat w profesji</div>
                    <div className="col-6xs btn btn-border">własna praktyka</div>
                    <div className="col-6xs btn btn-border">ponad 4000 operacji</div>
                  </div>
                </div>
              </div>
              <div className="col-12 flex-txt">
                <p>Nazywam się Maciej Pastucha. Posiadam ponad 30-letnie doświadczenie w pracy z pacjentami. Od ponad 20 lat chirurgia plastyczna (estetyczna) to nie tylko mój zawód, ale także wielka pasja. Centrum chirurgii plastycznej prowadzę wraz z żoną Elżbietą, która jest specjalistą chorób wewnętrznych.</p>
                <p>Po ukończeniu studiów doskonaliłem swoją wiedzę i umiejętności jako asystent w Zakładzie Anatomii Prawidłowej. Utrwaliłem tam praktyczną oraz teoretyczną znajomość anatomii człowieka, co niewątpliwie procentuje do dzisiaj w trakcie wykonywania operacji plastycznych. Równocześnie w renomowanej Klinice Chirurgii Przewodu Pokarmowego w Katowicach zdobyłem specjalizację I stopnia z chirurgii ogólnej. W 2000 roku uzyskałem tytuł specjalisty chirurgii plastycznej. Od 2001 roku zajmuję się wyłącznie chirurgią estetyczną (plastyczną). Jako chirurg plastyk wykonałem ponad 4000 operacji estetycznych (plastycznych), w tym około 1400 samych operacji powiększania i rekonstrukcji piersi.</p>
                <p>Swoje doświadczenie wykorzystałem przy pisaniu pracy doktorskiej p.t.: “Ocena wybranych wskaźników jakości życia u kobiet poddających się operacji korekcyjnej sutków”, którą obroniłem w 2017 roku, otrzymując tytuł doktora nauk medycznych. Troska o długofalowy komfort życia moich pacjentek po zabiegu zaowocowała uzyskaniem tytułu naukowego, który jest częścią nieustannego poszerzania kwalifikacji zawodowych.</p>
                <p>Od roku 2002 mieszkam i pracuję w Szczecinie.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="nogutter">
        <div className="col-4xs-12 mrg-b"><img
          src="/uploads/2015/09/about_3_1-896x517-c-default.jpg"
          alt="Chirurgia plastyczna w Szczecinie - Dr Maciej Pastucha" /></div>
        <div className="col-4xs-12 mrg-b"><img
          src="/uploads/2015/09/about_3_2-896x517-c-default.jpg"
          alt="dr maciej pastucha szczecin" /></div>
        <div className="col-4xs-12 mrg-b"><img
          src="/uploads/2015/09/about_3_3-896x517-c-default.jpg"
          alt="maciej pastucha szczecin" /></div>
      </section>
      <section className="sct container">
        <div className="row">
          <div className="col-10sm-center">
            <div className="col-12 flex-txt">
              <p>Moje praktyczne doświadczenie w chirurgii estetycznej (plastycznej) w połączeniu z rzetelną wiedzą
                teoretyczną zdobytą w czasie licznych kursów medycznych zarówno w Polsce (Szczecin, Warszawa, Polanica
                Zdrój, Wrocław, Poznań), jak i za granicą (Sztokholm, Wiedeń, Paryż, Londyn, Barcelona, Madryt,
                Mediolan, Düsseldorf) zapewnia uzyskanie oczekiwanych rezultatów operacyjnych.</p>
              <p>Współpracuję z firmami: <a href="http://www.piersi.info.pl" target="_blank"
                                            rel="noopener">Mentor</a>, <a href="http://www.galderma.pl" target="_blank"
                                                                          rel="noopener">Galderma</a> oraz <a
                href="http://www.allergan.pl" target="_blank" rel="noopener">Allergan</a>.</p>
              <p>Umiejętności przyswojone w trakcie staży oraz kursów w polskich i zagranicznych klinikach umożliwiają
                mi wykonywanie operacji na najwyższym światowym poziomie. Pacjentom daje to gwarancję bezpieczeństwa, a
                także satysfakcję z przeprowadzanych zabiegów. Dowodem na to są serdeczne podziękowania, wyrazy uznania
                i ciepłe słowa, które od lat otrzymuję od zadowolonych pacjentów.</p>
              <p style={{ textAlign: "center" }}>Przeczytaj moje ostatnie publikacje:</p>
              <div className="btn-list"><a className="btn btn-contrast btn-contrast-wide"
                                           href="/uploads/2016/02/Physical-Attractiveness-and-Self-Assessment-Before-and-After-Breast-Augmentation-1.pdf"
                                           target="_blank" rel="noopener">Physical Attractiveness and Self-Assessment
                Before and After Breast Augmentation</a></div>
              <div className="btn-list"><a className="btn btn-contrast btn-contrast-wide"
                                           href="http://dlibra.umcs.lublin.pl/Content/21805/czas19356_25_1_2_2012_5.pdf">Wokół
                operacji plastycznej piersi – jakość życia, motywy i oczekiwania</a></div>
              <div className="btn-list">
                {/*<a href className="btn btn-contrast btn-contrast-wide" target="_blank" rel="nofollow noopener"></a>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sct container">
        <div className="row">
          <div className="col-6sm col-6-narrow">
            <div className="txt-cnt">
              <h3>Wykształcenie i przebieg pracy zawodowej:</h3>
            </div>
            <ul className="cv-ul">
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1980 - 1986</p>
                  </div>
                  <div className="col-9xs">
                    <p>Studia Medyczne - Śląski Uniwersytet Medyczny w Katowicach</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1990</p>
                  </div>
                  <div className="col-9xs">
                    <p>Specjalizacja I stopnia z chirurgii ogólnej</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>2000</p>
                  </div>
                  <div className="col-9xs">
                    <p>Specjalizacja II stopnia z chirurgii plastycznej</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>2004</p>
                  </div>
                  <div className="col-9xs">
                    <p>Otwarcie przewodu doktorskiego w Pomorskim Uniwersytecie Medycznym w Szczecinie</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1986 - 1994</p>
                  </div>
                  <div className="col-9xs">
                    <p>Asystent w Katedrze i Zakładzie Anatomii Prawidłowej Śląskiej Akademii Medycznej w Katowicach</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1988 - 1994</p>
                  </div>
                  <div className="col-9xs">
                    <p>Asystent w Klinice Chirurgii Przewodu Pokarmowego ŚlAM w Katowicach</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1994 - 2002</p>
                  </div>
                  <div className="col-9xs">
                    <p>Asystent Oddziału Chirurgii Plastycznej Szpitala Wojewódzkiego Nr.5 w Sosnowcu</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1996 - 2002</p>
                  </div>
                  <div className="col-9xs">
                    <p>Asystent Wojewódzkiej Poradni Chirurgii Plastycznej w Katowicach</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>1996 - 2002</p>
                  </div>
                  <div className="col-9xs">
                    <p>Chirurg plastyk w prywatnych ośrodkach chirurgii plastycznej w Katowicach, Tychach i
                      Myszkowie</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>2003 - 2006</p>
                  </div>
                  <div className="col-9xs">
                    <p>Kontrakt z NFZ na usługi medyczne w Samodzielnym Publicznym Szpitalu Klinicznym Nr 2 w
                      Szczecinie</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>2002-2012</p>
                  </div>
                  <div className="col-9xs">
                    <p>Dyrektor Medyczny Kliniki Chirurgii Plastycznej</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>od 2011</p>
                  </div>
                  <div className="col-9xs">
                    <p>Właściciel Centrum Chirurgii Plastycznej</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-6sm col-6-narrow">
            <div className="txt-cnt">
              <h3>Członkostwo</h3>
            </div>
            <ul className="cv-ul">
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>od 1994</p>
                  </div>
                  <div className="col-9xs">
                    <p>Członek Polskiego Towarzystwa Chirurgii Plastycznej Rekonstrukcyjnej i Estetycznej</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>od 1994</p>
                  </div>
                  <div className="col-9xs">
                    <p>International Confederation for Plastic, Reconstructive and Aesthetic Surgery</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-3xs cv-ul-date">
                    <p>od 1994</p>
                  </div>
                  <div className="col-9xs">
                    <p>European Society of Plastic, Reconstructive and Aesthetic Surgery</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="mrg-t">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="txt-cnt mrg-b">
                <h2>Certyfikaty</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="sct bg-white">
                  <div className="owl-carousel certificates">
                    <div className="item"><Link to="/uploads/2015/09/Certyfikat-21.jpg"
                                             data-size="1400x1004"> <img
                      src="/uploads/2015/09/Certyfikat-21-1024x734-0x250-c-default.jpg"
                      alt="Certyfikat Maciej Pastucha" /></Link></div>
                    <div className="item"><a
                      href="/uploads/2015/09/Certyfikat-1-846x1200.jpg"
                      data-size="846x1200"> <img
                      src="/uploads/2015/09/Certyfikat-1-722x1024-0x250-c-default.jpg"
                      alt="Certyfikat Dr n. med. Maciej Pastucha" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/3H.jpg"
                                             data-size="1600x1162"> <img
                      src="/uploads/2015/09/3H-1024x744-0x250-c-default.jpg"
                      alt="chirurg plastyczny dr Maciej Pastucha certyfikat" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/4-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/4-743x1024-0x250-c-default.jpg"
                      alt="chirurg plastyczny Maciej Pastucha certyfikat" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/5-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/5-743x1024-0x250-c-default.jpg"
                      alt="certyfikat chirurg plastyczny Szczecin" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/6-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/6-743x1024-0x250-c-default.jpg"
                      alt="certyfikat chirurg plastyczny" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/7-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/7-743x1024-0x250-c-default.jpg"
                      alt="certyfikat chirurgia plastyczna szczecin" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/8-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/8-743x1024-0x250-c-default.jpg"
                      alt="certyfikat chirurgia plastyczna" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/9H.jpg"
                                             data-size="1600x1162"> <img
                      src="/uploads/2015/09/9H-1024x744-0x250-c-default.jpg"
                      alt="centrum chirurgii plastycznej certyfikat" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/10-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/10-743x1024-0x250-c-default.jpg"
                      alt="certyfikat maciej pastucha szczecin" /></a></div>
                    <div className="item"><a href="/uploads/2015/09/11-871x1200.jpg"
                                             data-size="871x1200"> <img
                      src="/uploads/2015/09/11-743x1024-0x250-c-default.jpg"
                      alt="certyfikat pastucha szczecin" /></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sct container">
        <div className="row">
          <div className="col-4sm-center">
            <div className="hero-award about-award small">
              <p>Nagrodzony przez:</p>
              <div className="hero-award-logo"><img
                src="/uploads/2015/07/twojstyl_red.png" alt="Twój Styl" /></div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}
